.left-addon-select {
    .uxf-input,
    .uxf-input__wrapper {
        border-bottom-right-radius: 0;
        border-right: none;
        border-top-right-radius: 0;
        box-shadow: none;

        &.is-invalid {
            border-left: 1px;
        }
    }
}

.uxf-input--has-left-addon {
    .uxf-input__left-addon {
        border-width: 0;
    }

    .left-addon-select {
        width: 110px;

        .uxf-input,
        .uxf-input__wrapper {
            border-bottom-left-radius: 6px;
            border-top-left-radius: 6px;
        }
    }
}
