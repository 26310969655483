.uxf-wysiwyg {
    &__toolbar {
        border: theme("borderWidth.DEFAULT") solid theme("borderColor.lightBorder");
        border-radius: theme("borderRadius.md") theme("borderRadius.md") 0 0;
        margin-bottom: 0;
        padding: theme("spacing.4");
    }

    &__content {
        border: theme("borderWidth.DEFAULT") solid theme("borderColor.lightBorder");
        border-radius: 0 0 theme("borderRadius.md") theme("borderRadius.md");
        border-top: none;
        min-height: 152px;
        padding: theme("spacing.4");
    }

    &__editable {
        min-height: 152px !important;
    }
}
