.uxf-button {
    border-radius: theme("borderRadius.lg");

    &--variant-default {
        &.uxf-button--color-gray {
            @apply focus-visible:ring-gray-900;

            :root .light & {
                @apply bg-gray-600 is-hoverable:bg-gray-800;
            }

            :root .dark & {
                @apply bg-gray-200 is-hoverable:bg-gray-400;
            }

            &.is-disabled {
                :root .light & {
                    @apply bg-gray-300 text-lightLow;
                }

                :root .dark & {
                    @apply bg-gray-600 text-gray-400;
                }
            }
        }
    }
}
