@import url("@uxf/ui/css/icon.css");
@import url("@uxf/ui/css/input-basic.css");
@import url("@uxf/ui/css/loader.css");
@import url("@uxf/ui/css/paper.css");
@import url("@uxf/ui/css/typography.css");

/* — */
@import url("@uxf/ui/css/alert-bubble.css");
@import url("@uxf/ui/css/badge.css");
@import url("@uxf/ui/css/button.css");
@import url("../components/button/button.css");
@import url("@uxf/ui/css/calendar.css");
@import url("@uxf/ui/css/chip.css");
@import url("@uxf/ui/css/color-radio.css");
@import url("../components/color-radio/color-radio.css");
@import url("@uxf/ui/css/color-radio-group.css");
@import url("@uxf/ui/css/checkbox.css");
@import url("@uxf/ui/css/checkbox-button.css");
@import url("../components/checkbox-button/checkbox-button.css");
@import url("@uxf/ui/css/checkbox-input.css");
@import url("../components/checkbox-input/checkbox-input.css");
@import url("@uxf/ui/css/dialog.css");
@import url("../components/dialog/dialog.css");
@import url("@uxf/ui/css/error-message.css");
@import url("@uxf/ui/css/form-component.css");
@import url("@uxf/ui/css/label.css");
@import url("@uxf/ui/css/radio.css");
@import url("@uxf/ui/css/radio-group.css");
@import url("@uxf/ui/css/input-with-popover.css");
@import url("@uxf/ui/css/text-link.css");
@import url("../components/text-link/text-link.css");

/* — */
@import url("@uxf/ui/css/avatar.css");
@import url("@uxf/ui/css/button-list.css");
@import url("../components/button-list/button-list.css");
@import url("@uxf/ui/css/button-group.css");
@import url("@uxf/ui/css/combobox.css");
@import url("@uxf/ui/css/input.css");
@import url("../components/input/input.css");
@import url("@uxf/ui/css/file-input.css");
@import url("@uxf/ui/css/avatar-file-input.css");
@import url("../components/avatar-file-input/avatar-file-input.css");
@import url("@uxf/ui/css/multi-combobox.css");
@import url("@uxf/ui/css/date-picker.css");
@import url("@uxf/ui/css/datetime-picker.css");
@import url("@uxf/ui/css/dropdown.css");
@import url("@uxf/ui/css/dropzone.css");
@import url("../components/dropzone/dropzone.css");
@import url("@uxf/ui/css/flash-messages.css");
@import url("@uxf/ui/css/layout.css");
@import url("@uxf/ui/css/list-item.css");
@import url("@uxf/ui/css/message.css");
@import url("@uxf/ui/css/modal.css");
@import url("../components/modal/modal.css");
@import url("@uxf/ui/css/pagination.css");
@import url("@uxf/ui/css/raster-image.css");
@import url("@uxf/ui/css/multi-select.css");
@import url("@uxf/ui/css/select.css");
@import url("@uxf/ui/css/tabs.css");
@import url("../components/tabs/tabs.css");
@import url("@uxf/ui/css/textarea.css");
@import url("@uxf/ui/css/time-picker.css");
@import url("@uxf/ui/css/toggle.css");
@import url("@uxf/ui/css/tooltip.css");
@import url("../components/tooltip/tooltip.css");

/* — */
@import url("@uxf/data-grid/styles.css");
@import url("../components/data-grid/data-grid.css");
@import url("@uxf/cms/utils/styles.css");
@import url("./cms.css");
@import url("@uxf/wysiwyg/styles/styles.css");
@import url("../components/wysiwyg/wysiwyg.css");

/* add or override */
@import url("../components/select/select.css");

.wysiwyg > [data-slate-editor] {
    word-break: break-word;

    *:is(h1, h2, h3, h4, h5, h6) {
        margin-bottom: 0.5em;
    }

    * + * {
        margin-top: 1em;

        &:is(h1, h2, h3, h4, h5, h6) {
            margin-top: 1em;
        }

        &:is(li) {
            margin-top: 0.5em;
        }
    }

    *:is(h1, h2, h3, h4, h5, h6) + *:is(h1, h2, h3, h4, h5, h6) {
        margin-top: 0.5em;
    }
}

.wysiwyg-renderer {
    @apply text-lightMedium dark:text-darkMedium;

    word-break: break-word;

    *:is(h1, h2, h3, h4, h5, h6) {
        margin-bottom: 0.5em;
    }

    * + * {
        margin-top: 1em;

        &:is(h1, h2, h3, h4, h5, h6) {
            margin-top: 1em;
        }

        &:is(li) {
            margin-top: 0.5em;
        }
    }

    *:is(h1, h2, h3, h4, h5, h6) + *:is(h1, h2, h3, h4, h5, h6) {
        margin-top: 0.5em;
    }

    *:is(strong, br) + *:is(strong, br) {
        margin-top: 0;
    }
}
