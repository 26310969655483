.uxf-text-input {
    &.is-registered {
        .uxf-input__wrapper {
            background-color: theme("colors.success.200");
        }
    }

    &.positive-amount {
        .uxf-input__wrapper {
            background-color: theme("colors.success.100");
        }
    }

    &.negative-amount {
        .uxf-input__wrapper {
            background-color: theme("colors.error.100");
        }
    }
}
