.uxf-dialog {
    --panel-border-radius: theme("borderRadius.lg");

    &--variant-rightDrawer {
        .uxf-dialog__body {
            @screen sm {
                align-items: stretch;
                justify-content: flex-end;
                padding: 0;
            }
        }

        .uxf-dialog__panel {
            @screen sm {
                border-radius: 0;
            }
        }
    }
}
