.uxf-checkbox-button {
    border: theme("borderWidth.DEFAULT") solid theme("borderColor.lightBorder");
    font-weight: theme("fontWeight.medium");
    min-width: 42px;
    padding: theme("spacing.2") theme("spacing[2.5]");

    &__icon {
        display: none;
    }

    :root .light & {
        background-color: theme("backgroundColor.white");
    }

    &__label {
        font-weight: theme("fontWeight.medium");
    }
}
