.uxf-tabs-vertical {
    @screen lg {
        --tabs-width: 256px;
        --tabs-panels-gap: 32px;

        display: flex;
        gap: var(--tabs-panels-gap);

        .uxf-tabs__tab-list__wrapper {
            flex-shrink: 0;
            width: var(--tabs-width);

            &--default {
                @apply before:border-none;
            }
        }

        .uxf-tabs__tab-list {
            display: flex;
            flex-direction: column;
            padding: theme("spacing[1.5]");

            &--default {
                @apply space-x-0 space-y-0;
            }
        }

        .uxf-tabs__tab {
            border-radius: 0;
            display: block;
            padding: theme("spacing[2.5]") theme("spacing.2");
            text-align: left;
            width: 100%;

            &--default {
                @apply relative before:absolute before:left-[-6px] before:top-0 before:h-full before:border-b-0 before:border-l before:opacity-100 before:transition
                    after:absolute after:inset-0 after:z-1 focus-visible:ring-0 focus-visible:ring-offset-0 after:focus-visible:ring-2 after:focus-visible:ring-offset-1;

                :root .light & {
                    @apply before:border-l-lightBorder after:focus-visible:ring-primary;
                }

                :root .dark & {
                    @apply before:border-l-darkBorder after:focus-visible:ring-primary;
                }

                &.is-active {
                    @apply before:border-l-2;

                    :root .light & {
                        @apply before:border-l-primary;
                    }

                    :root .dark & {
                        @apply before:border-l-primary;
                    }
                }
            }
        }

        .uxf-tabs__panels {
            flex-grow: 1;
            max-width: calc(100% - var(--tabs-width) - var(--tabs-panels-gap));
        }
    }
}
