.uxf-text-link {
    @apply relative underline outline-none transition before:absolute before:-inset-1
        focus-visible:before:rounded-lg focus-visible:before:border-2;

    &--variant-default {
        :root .light & {
            @apply text-primary-500 focus-visible:before:border-primary-500 is-hoverable:text-primary-400;
        }
    }

    &--variant-text {
        @apply text-inherit focus-visible:before:border-inherit is-hoverable:opacity-75;
    }
}
