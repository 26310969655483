.uxf-checkbox-input {
    &__wrapper {
        @apply space-x-3;

        justify-content: flex-start;
    }

    &__label {
        font-weight: theme("fontWeight.medium");
    }
}
